import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface CityState {
  pagination: Pagination
  query: string
}

const initialState: CityState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    setPaginationCity: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setQueryCity: (state, action: PayloadAction<string>) => {
      state.query = action.payload
      state.pagination = {
        page: 0,
        size: 10,
      }
    },
  },
})

export const selectCityPagination = (state: RootState) => state.city.pagination
export const selectCityQuery = (state: RootState) => state.city.query
export const { setPaginationCity, setQueryCity } = citySlice.actions
export default citySlice.reducer
