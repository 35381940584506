// UserDialog.tsx
import React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { User } from "../../../../gql/graphql"

interface UserDialogProps {
  open: boolean
  handleClose: () => void
  user?: User | null | undefined
}

const UserDialog: React.FC<UserDialogProps> = ({ open, handleClose, user }) => {
  if (!user) return null
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="user-dialog-title"
      aria-describedby="user-dialog-description"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          background: "white",
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="Fermer"
          title="Fermer"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
            background: "red",
            "&:hover": {
              background: "red",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Card>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar
                  alt={user.name}
                  src={user?.profile?.url}
                  sx={{ mr: 2 }}
                />
                <Typography variant="h5">
                  {`${user.name} ${user.lastName}` || "Pas définie"}
                </Typography>
              </Box>
              <Typography variant="body1" mt={2}>
                <strong>ID:</strong> {user.id}
              </Typography>
              <Typography variant="body1" mt={2}>
                <strong>Email:</strong> {user.email || "Pas définie"}
              </Typography>
              <Typography variant="body1" mt={2}>
                <strong>Nom:</strong> {user?.name || "Pas définie"}
              </Typography>
              <Typography variant="body1" mt={2}>
                <strong>Prénom:</strong> {user?.lastName || "Pas définie"}
              </Typography>
              <Typography variant="body1" mt={2}>
                <strong>Pays:</strong> {user?.Country?.name_fr || "Pas définie"}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default UserDialog
