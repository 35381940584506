import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface CommissionState {
  pagination: Pagination
  query: string
}

const initialState: CommissionState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const commissionSlice = createSlice({
  name: "commission",
  initialState,
  reducers: {
    setPaginationCommission: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setQueryCommission: (state, action: PayloadAction<string>) => {
      state.query = action.payload
      state.pagination = {
        page: 0,
        size: 10,
      }
    },
  },
})

export const selectCommissionPagination = (state: RootState) =>
  state.commission.pagination
export const selectCommissionQuery = (state: RootState) =>
  state.commission.query
export const { setPaginationCommission, setQueryCommission } =
  commissionSlice.actions
export default commissionSlice.reducer
