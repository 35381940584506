// UserDialog.tsx
import React, { useEffect, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Button,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useLazyQuery } from "@apollo/client"
import { graphql } from "../../../../gql"
import Loader from "../../../Common/Loader/Loader"
import UserDialog from "../UserModal/UserModal"
import { User } from "../../../../gql/graphql"

const COMPAGNY = graphql(`
  query FindFirstCompagny($where: CompagnyWhereInput) {
    findFirstCompagny(where: $where) {
      id
      name
      email
      primaryColor
      secondaryColor
      slug
      owner {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          url
        }
      }
      logo {
        url
      }
    }
  }
`)

interface CompagnyModalProps {
  open: boolean
  handleClose: () => void
  compagnyId?: number | null | undefined
}

const CompagnyModal: React.FC<CompagnyModalProps> = ({
  open,
  handleClose,
  compagnyId,
}) => {
  const [getCompgany, { loading, error, data }] = useLazyQuery(COMPAGNY)
  const [isOpen, setOpen] = useState(false)
  useEffect(() => {
    getCompgany({
      variables: {
        where: {
          id: {
            equals: compagnyId,
          },
        },
      },
    })
  }, [compagnyId])

  if (!compagnyId || error) return null
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="user-dialog-title"
      aria-describedby="user-dialog-description"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          background: "white",
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="Fermer"
          title="Fermer"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
            background: "red",
            "&:hover": {
              background: "red",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Card>
            {loading ? (
              <Box p={4}>
                <Loader />
              </Box>
            ) : (
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar
                    alt={data?.findFirstCompagny?.name}
                    src={data?.findFirstCompagny?.logo?.url}
                    sx={{ mr: 2 }}
                  />
                  <Typography variant="h5">
                    {data?.findFirstCompagny?.name || "Pas définie"}
                  </Typography>
                </Box>
                <Typography variant="body1" mt={2}>
                  <strong>ID: </strong>{" "}
                  {data?.findFirstCompagny?.id || "Pas définie"}
                </Typography>
                <Typography variant="body1" mt={2}>
                  <strong>Email de contact: </strong>{" "}
                  {data?.findFirstCompagny?.email || "Pas définie"}
                </Typography>
                <Button
                  variant="text"
                  sx={{ mt: 2, color: "white" }}
                  onClick={() => setOpen(true)}
                >
                  <strong>Créateur ID: </strong>{" "}
                  {data?.findFirstCompagny?.owner.id || "Pas définie"}
                </Button>
                <Typography variant="body1" mt={2}>
                  <strong>Slug: </strong>{" "}
                  {data?.findFirstCompagny?.slug || "Pas définie"}
                </Typography>
              </CardContent>
            )}
          </Card>
        </Box>
        <UserDialog
          handleClose={() => setOpen(false)}
          open={isOpen}
          user={data?.findFirstCompagny?.owner as User}
        />
      </DialogContent>
    </Dialog>
  )
}

export default CompagnyModal
