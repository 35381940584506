import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"

const LIST_EVENTS = graphql(`
  query ListEvents(
    $where: EventWhereInput
    $orderBy: [EventOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    events(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      title
    }
  }
`)

interface Props {
  getEvent: (event: any) => void
  eventDefault: InfinityListItem | InfinityListItem[]
  label?: string
  required?: boolean
  multiple?: boolean
}
const CustomListEvent = ({
  getEvent,
  eventDefault,
  label = "Évenement",
  required = true,
  multiple = false,
}: Props) => {
  const [event, setEvent] = useState(eventDefault)

  const [queryEvent, setQueryEvent] = useState("")
  const [openEvent, setOpenEvent] = useState(false)
  const size = 30

  const queryTitle = useCallback(() => {
    const OrClause = {
      OR: [{ title: { contains: queryEvent, mode: QueryMode.Insensitive } }],
    }
    return queryEvent ? OrClause : {}
  }, [queryEvent])

  const { loading, data, fetchMore } = useQuery(LIST_EVENTS, {
    variables: {
      take: size,
      orderBy: [{ title: SortOrder.Asc }],
      ...(queryEvent && {
        where: {
          ...queryTitle(),
        },
      }),
    },
  })

  const handleSearchCategory = (query: string) => {
    setQueryEvent(query)
  }

  const handleCloseCategory = () => {
    setOpenEvent(false)
  }
  const handleOpenEvent = async () => {
    setOpenEvent(true)
  }

  const onEndReachedCategory = () => {
    fetchMore({
      variables: {
        skip: data?.events.length,
      },
    })
  }

  useEffect(() => {
    if (event && eventDefault) {
      if (!Array.isArray(event) && !Array.isArray(eventDefault)) {
        if (!event?.value && eventDefault?.value) {
          setEvent(eventDefault)
        }
      } else {
        setEvent(eventDefault)
      }
    }
  }, [event, eventDefault])

  const dataEvent = data?.events

  const events =
    loading || !dataEvent
      ? []
      : dataEvent.map((el) => ({ label: el.title, value: el.id }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseCategory}
      handleOpen={handleOpenEvent}
      open={openEvent}
      defaultValue={eventDefault}
      keyName="label"
      label={label}
      onSearch={handleSearchCategory}
      options={openEvent ? events : []}
      loading={loading}
      multiple={multiple}
      onChange={(value) => {
        if (value) {
          setEvent(value)
          getEvent(value)
        }
      }}
      query={queryEvent}
      value={event}
      onEndReached={onEndReachedCategory}
      required={required}
    />
  )
}

export default CustomListEvent
