import React from "react"
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Checkbox,
  TextField,
  Pagination,
  Button,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import VisibilityIcon from "@mui/icons-material/Visibility"
import RefreshIcon from "@mui/icons-material/Refresh"
import { useNavigate } from "react-router-dom"
import { havePermissions } from "../../../utils/permissions"

interface MobileTableViewProps {
  data: any[]
  columns: any[]
  handleDelete?: (id: number) => void
  handleEdit?: (id: number) => void
  handleView?: (id: number) => void
  handleSelected?: (id: number) => void
  selectedIds?: number[]
  isHaveView?: boolean
  isHaveAction?: boolean
  isCustomAction?: boolean
  renderAction?: any
  rowCount: number
  pageIndex: number
  pageSize: number
  handleChangePagination: (pagination: { page: number; size: number }) => void
  searchValue?: string
  onChangeSearchValue?: (value: string) => void
  isHaveHeaderAction?: boolean
  lableAddNew?: string
  handleAdd?: () => void
  rootLisName?: string
  handleRefresh?: () => void
  handleDeleteGroup?: () => void
  permissionDelete: string
  permissionCreate: string
  permissionChange: string
  permissionView: string
  childrenFilter?: React.ReactNode
}

const MobileTableView: React.FC<MobileTableViewProps> = ({
  data,
  columns,
  handleDelete,
  handleEdit,
  handleView,
  handleSelected,
  selectedIds,
  isHaveView,
  isHaveAction,
  isCustomAction,
  renderAction,
  rowCount,
  pageIndex,
  pageSize,
  handleChangePagination,
  searchValue,
  onChangeSearchValue,
  handleAdd,
  handleDeleteGroup,
  handleRefresh,
  isHaveHeaderAction,
  lableAddNew,
  rootLisName,
  permissionChange,
  permissionCreate,
  permissionDelete,
  permissionView,
  childrenFilter,
}) => {
  const navigate = useNavigate()
  return (
    <Box sx={{ mt: 2, overflowY: "auto" }}>
      {isHaveHeaderAction && lableAddNew && (
        <Box sx={{ mb: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
          {havePermissions([permissionCreate]) && (
            <Button
              variant="contained"
              sx={{ background: "white", color: "#000" }}
              onClick={() => {
                if (handleAdd) {
                  handleAdd()
                } else if (rootLisName) {
                  navigate(rootLisName)
                }
              }}
            >
              {lableAddNew}
            </Button>
          )}
          {handleRefresh && (
            <Button
              variant="contained"
              color="success"
              sx={{ color: "#fff" }}
              onClick={handleRefresh}
            >
              <RefreshIcon />
            </Button>
          )}
          {Boolean(selectedIds?.length && handleDeleteGroup) &&
            havePermissions([permissionDelete]) && (
              <Button
                variant="contained"
                color="error"
                sx={{ color: "#fff" }}
                onClick={handleDeleteGroup}
              >
                <DeleteIcon />
              </Button>
            )}
        </Box>
      )}{" "}
      <TextField
        label=""
        variant="outlined"
        placeholder="Rechercher..."
        fullWidth
        value={searchValue}
        onChange={(e) =>
          onChangeSearchValue && onChangeSearchValue(e.target.value)
        }
        sx={{ mb: 2, background: "dimgray" }}
      />
      {childrenFilter && childrenFilter}
      {data.map((row) => (
        <Card key={row.id} sx={{ mb: 2 }}>
          <CardContent>
            {isHaveAction && (
              <Box
                sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}
              >
                {handleSelected && havePermissions([permissionDelete]) && (
                  <Checkbox
                    checked={selectedIds?.includes(row.id)}
                    onChange={() => handleSelected(row.id)}
                    color="primary"
                  />
                )}
                {handleEdit && havePermissions([permissionChange]) && (
                  <IconButton onClick={() => handleEdit(row.id)}>
                    <EditIcon />
                  </IconButton>
                )}
                {handleDelete && havePermissions([permissionDelete]) && (
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(row.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                {isHaveView &&
                  handleView &&
                  havePermissions([permissionView]) && (
                    <IconButton color="info" onClick={() => handleView(row.id)}>
                      <VisibilityIcon />
                    </IconButton>
                  )}
              </Box>
            )}
            {isCustomAction && renderAction && (
              <Box sx={{ mt: 2 }}>
                {renderAction({ row, cell: null, table: null })}
              </Box>
            )}
            {columns.map((column) => (
              <Box key={column.accessorKey || column.header} sx={{ mb: 1 }}>
                <Box sx={{ textDecorationLine: "underline" }}>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    fontWeight={"bold"}
                  >
                    {column.header}:
                  </Typography>
                </Box>{" "}
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2" component="span">
                    {column.accessorFn
                      ? column.accessorFn(row)
                      : row[column.accessorKey]}
                  </Typography>
                </Box>
              </Box>
            ))}
          </CardContent>
        </Card>
      ))}
      <Pagination
        count={Math.ceil(rowCount / pageSize)}
        page={pageIndex + 1}
        color="secondary"
        variant="outlined"
        showFirstButton
        showLastButton
        onChange={(_, page) =>
          handleChangePagination({ page: page - 1, size: pageSize })
        }
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "center",
          background: "dimgray",
          p: 1.5,
          borderRadius: 1,
        }}
      />
    </Box>
  )
}

export default MobileTableView
