import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete"
export interface GooglePlace {
  longitude: number
  latitude: number
  googlePlaceId: string
  googleAdress: string
}

interface Props {
  getPlace?: (place: GooglePlace) => void
}

const CustomGooglePlacesAutocomplete = ({ getPlace }: Props) => {
  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyD7fr1Fcjtd5cooE3wrtiMOm3Strp1JmqM"
        debounce={300}
        minLengthAutocomplete={2}
        selectProps={{
          placeholder: "Rechercher une adresse...",
          loadingMessage(obj) {
            return "Recherche en cours..."
          },
          noOptionsMessage(obj) {
            return "Aucune adresse trouvée."
          },
          onChange(newValue) {
            const placeId = newValue?.value?.place_id
            if (placeId) {
              geocodeByPlaceId(placeId).then((response) => {
                if (response.length) {
                  const place = response[0]
                  if (place) {
                    const { place_id, geometry, formatted_address } = place
                    if (getPlace) {
                      getPlace({
                        googleAdress: formatted_address,
                        googlePlaceId: place_id,
                        latitude: geometry?.location.lat(),
                        longitude: geometry?.location.lng(),
                      })
                    }
                  }
                }
              })
            }
          },
          styles: {
            //@ts-ignore
            input: (provided) => ({
              ...provided,
              minHeight: "45px",
            }),
            //@ts-ignore
            option: (provided) => ({
              ...provided,
              color: "black",
            }),
            //@ts-ignore
            singleValue: (provided) => ({
              ...provided,
              color: "black",
            }),
            //@ts-ignore
            menuList: (provided) => ({
              ...provided,
              zIndex: 9999,
            }),
          },
        }}
        apiOptions={{
          language: "fr",
        }}
      />
    </div>
  )
}

export default CustomGooglePlacesAutocomplete
