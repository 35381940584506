import { gql } from "@apollo/client"

export const EVENT_FRAGMENT = gql(`
  fragment EventFragment on Event {
    id
    latitude
    longitude
    numberPeopleMax
    title
    isPublish
    descriptions
    isValidate
    endedDate
    frequency
    commissionCategory
    isValidateOnCompagny
    isFromScrapping
    googlePlaceId
    googleAdress
    startedDate
    startedTime
    endedTime
    isFree
    isOpenTicket
    priority
    compagny {
      id
      name
    }
    country {
      id
      name_fr
    }
    mediators {
      id
      eventId
      invitationStatus
      user {
        id
        name
        lastName
        email
      }
    }
    ticketPricings {
      id
      isPublic
      name
      nbAvailible
      price
      descriptions
    }
    categories {
      id
      name
    }
    creator {
      id
      name
      lastName
      email
      Country {
        name_fr
      }
      profile {
        id
        url
      }
    }
    place {
      id
      name
    }
    city {
      id
      name_fr
    }
    coverImage {
      id
      url
    }
  }
`)
