import { useCallback, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/app/hooks"
import { useMutation, useQuery } from "@apollo/client"
import {
  selectUserPagination,
  selectUserQuery,
  setUserPagination,
  setQuery,
} from "../../store/features/user/userSlice"
import { graphql } from "../../gql"
import CustomTable from "../Common/CustomTable/CustomTable"
import Loader from "../Common/Loader/Loader"
import {
  QueryMode,
  SortOrder,
  Ticket,
  TicketWhereInput,
} from "../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../types/Pagination"
import moment from "moment"
import { havePermissions } from "../../utils/permissions"
import { Box, Button } from "@mui/material"
import ImageDownloadModal from "../Common/ImageDownloadModal/ImageDownloadModal"
import EventTicketModal from "./EventTicketModal/EventTicketModal"
import { getErrorsAsString } from "../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../store/features/snackbar/snackbarSlice"

export const LIST_TICKETS = graphql(`
  query Tickets(
    $where: TicketWhereInput
    $orderBy: [TicketOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $aggregateTicketWhere2: TicketWhereInput
  ) {
    tickets(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      email
      qrCodeDataUrl
      imgSrc
      createdAt
      event {
        id
        title
      }
      user {
        email
      }
    }
    aggregateTicket(where: $aggregateTicketWhere2) {
      _count {
        _all
      }
    }
  }
`)

const SEND_TICKET = graphql(`
  mutation SendTicketsToEmail($ticketIds: [Int!]!, $email: String!) {
    sendTicketsToEmail(ticketIds: $ticketIds, email: $email)
  }
`)

const Billets = () => {
  const { page, size } = useAppSelector(selectUserPagination)
  const query = useAppSelector(selectUserQuery)
  const dispatch = useAppDispatch()
  const [visible, setVisible] = useState(false)
  const [id, setId] = useState(0)
  const [image, setImage] = useState("")
  const [open, setOpen] = useState(false)
  const [sendTicket, { loading: lodingSend }] = useMutation(SEND_TICKET)
  const queryName: TicketWhereInput = useMemo(() => {
    return query
      ? {
          OR: [
            {
              event: {
                is: {
                  title: {
                    search: query,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
            {
              email: {
                search: query,
                mode: QueryMode.Insensitive,
              },
            },
            {
              ticketPricing: {
                is: {
                  name: {
                    search: query,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
            {
              user: {
                is: {
                  OR: [
                    {
                      email: {
                        search: query,
                        mode: QueryMode.Insensitive,
                      },
                    },
                    {
                      name: {
                        search: query,
                        mode: QueryMode.Insensitive,
                      },
                    },
                    {
                      lastName: {
                        search: query,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
            },
          ],
        }
      : {}
  }, [query])

  const { loading, data } = useQuery(LIST_TICKETS, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: { createdAt: SortOrder.Desc },
      ...(queryName && {
        where: { ...queryName },
        aggregateTicketWhere2: {
          ...queryName,
        },
      }),
    },
  })

  const handleView = useCallback(
    (id: number) => {
      const ticket = data?.tickets.find((el) => el.id == id)
      if (ticket) {
        setId(id)
        setImage(ticket.imgSrc || ticket.qrCodeDataUrl)
        setVisible(true)
      }
    },
    [data, setImage, setVisible, setId],
  )

  const handleSendTicket = useCallback(
    (e: any) => {
      e.stopPropagation()
      e.preventDefault()
      const ticket = data?.tickets.find((el) => el.id == id)
      if (ticket) {
        const email = ticket?.user?.email || ticket.email
        if (email) {
          sendTicket({
            variables: {
              email,
              ticketIds: [id],
            },
            onCompleted() {
              setVisible(false)
              dispatch(
                setOpenSnackbar({
                  status: "success",
                  message: "Le billet a été envoyé avec succès !",
                }),
              )
            },
            onError(error) {
              setVisible(false)
              const message = getErrorsAsString(error)
              dispatch(setOpenSnackbar({ message }))
            },
          })
        }
      }
    },
    [data, setVisible, id, sendTicket, dispatch, setOpenSnackbar],
  )

  const columns = useMemo<MRT_ColumnDef<Ticket>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID Ticket",
        enableEditing: false,
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row.email || row?.user?.email,
        header: "Client email",
        enableClickToCopy: true,
      },

      {
        accessorFn: (row) => (
          <>
            <Box
              component={"span"}
              onClick={() => {
                handleView(row.id)
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                src={row.qrCodeDataUrl}
                style={{ width: 50, height: 50 }}
              />
            </Box>
          </>
        ),
        header: "QR code",
      },
      {
        accessorFn: (row) => row.event.id,
        header: "ID événement",
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row.event.title,
        header: "Nom événement",
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => moment(row.createdAt).format("DD MMM YYYY"),
        header: "Date de création",
        enableClickToCopy: true,
      },
    ],
    [handleView, data],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setUserPagination(pagination))

  const onChangeSearchValue = (value: string) => dispatch(setQuery(value))

  const handleGenerateTickets = () => {
    setOpen(true)
  }

  if (loading && !query) return <Loader />

  return (
    <>
      <EventTicketModal handleClose={() => setOpen(false)} open={open} />
      <CustomTable
        columns={columns}
        data={data?.tickets || []}
        lableAddNew="Générer des tickets"
        rootLisName={""}
        isLoading={loading}
        showProgressBars={loading}
        rowCount={data?.aggregateTicket._count?._all || 0}
        handleChangePagination={handleChangePagination}
        pageIndex={page}
        pageSize={size}
        searchValue={query}
        permissionChange="admin.change"
        permissionCreate="admin.change"
        permissionDelete="admin.change"
        permissionView="admin.change"
        onChangeSearchValue={onChangeSearchValue}
        isHaveView={havePermissions(["admin.change"])}
        handleView={havePermissions(["admin.change"]) ? handleView : undefined}
        handleAdd={handleGenerateTickets}
      />
      <ImageDownloadModal
        imageUrl={image}
        onClose={() => setVisible(false)}
        open={Boolean(visible && image.length)}
        isUndimiss={lodingSend}
        actions={
          <Button
            variant="contained"
            color="success"
            onClick={handleSendTicket}
          >
            {lodingSend ? "Traitement..." : "Réenvoyer ce ticket"}
          </Button>
        }
      />
    </>
  )
}

export default Billets
