import React from "react"
import Switch from "react-switch"

interface Props {
  checked: boolean
  id?: string
  handleChange: (e: any) => void
  disabled?: boolean
}

const SwitchButton = ({ checked, handleChange, id, disabled }: Props) => {
  return (
    <Switch
      onChange={handleChange}
      checked={checked}
      id={id}
      disabled={disabled}
    />
  )
}

export default SwitchButton
