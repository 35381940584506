import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"

const LIST_USERS = graphql(`
  query ListUsers(
    $where: UserWhereInput
    $orderBy: [UserOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    users(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name
      lastName
    }
  }
`)

interface Props {
  getUser: (user: any) => void
  userDefault: InfinityListItem | InfinityListItem[]
  label?: string
  required?: boolean
  isID?: boolean
  multiple?: boolean
  isPlaceholder?: boolean
}
const CustomListUser = ({
  getUser,
  userDefault,
  label = "Utilisateur",
  required = true,
  multiple = false,
  isPlaceholder,
  isID,
}: Props) => {
  const [user, setUser] = useState(userDefault)
  const size = 30
  const [queryUser, setQueryUser] = useState("")
  const [openUser, setOpenUser] = useState(false)

  const queryName = useCallback(() => {
    const OrClause = {
      OR: isID
        ? [{ id: { equals: Number(queryUser) } }]
        : [
            { name: { contains: queryUser, mode: QueryMode.Insensitive } },
            { lastName: { contains: queryUser, mode: QueryMode.Insensitive } },
          ],
    }
    return queryUser ? OrClause : {}
  }, [queryUser, isID])

  const { loading, data, fetchMore } = useQuery(LIST_USERS, {
    variables: {
      take: size,
      orderBy: { name: SortOrder.Asc },
      ...(queryUser && {
        where: {
          ...queryName(),
        },
      }),
    },
  })

  const handleSearchCategory = (query: string) => {
    setQueryUser(query)
  }

  const handleCloseCategory = () => {
    setOpenUser(false)
  }
  const handleOpenUser = async () => {
    setOpenUser(true)
  }

  const onEndReachedCategory = () => {
    fetchMore({
      variables: {
        skip: data?.users.length,
      },
    })
  }

  useEffect(() => {
    if (user && userDefault) {
      if (!Array.isArray(user) && !Array.isArray(userDefault)) {
        if (!user?.value && userDefault?.value) {
          setUser(userDefault)
        }
      } else {
        setUser(userDefault)
      }
    }
  }, [user, userDefault])

  const dataUser = data?.users

  const users =
    loading || !dataUser
      ? []
      : dataUser.map((el) => ({
          label: isID ? el.id.toString() : el.lastName || el.name,
          value: el.id,
        }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseCategory}
      handleOpen={handleOpenUser}
      open={openUser}
      defaultValue={userDefault}
      keyName="label"
      label={label}
      inputMode={isID ? "numeric" : "text"}
      onSearch={handleSearchCategory}
      options={openUser ? users : []}
      loading={loading}
      multiple={multiple}
      onChange={(value) => {
        if (value) {
          setUser(value)
          getUser(value)
        }
      }}
      query={queryUser}
      value={user}
      onEndReached={onEndReachedCategory}
      required={required}
      isPlaceholder={isPlaceholder}
    />
  )
}

export default CustomListUser
