import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { ApolloError, useMutation, useQuery } from "@apollo/client"
import {
  selectEventPagination,
  setQueryEvent,
  setPaginationEvent,
  selectEventQuery,
} from "../../../store/features/event/eventSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import {
  SortOrder,
  Event,
  QueryMode,
  InvitationStatus,
  User,
  CommissionCategory,
  InputMaybe,
  EventWhereInput,
} from "../../../gql/graphql"
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import CustomImage from "../../Common/CustomImage/CustomImage"
import SwitchButton from "../../Common/SwitchButton/SwitchButton"
import { getValue } from "../../../utils/methodeString"
import moment from "moment"
import { updateCacheEvent } from "../../../caches/updateCacheEvent"
import { Chip, Grid, TextField } from "@mui/material"
import AddControllers from "./AddControllers/AddControllers"
import UserModal from "./UserModal/UserModal"
import { havePermissions } from "../../../utils/permissions"
import DescriptionModal from "./DescriptionModal/DescriptionModal"
import { debounce } from "lodash"
import EventStatisticModal from "../../Common/EventStatistic/EventStatisticModal/EventStatisticModal"
import UserInEventModal from "../../Common/ListUserInEvent/UserInEventModal/UserInEventModal"
import { InfinityListItem } from "../../../types/InfinityListItem"
import CustomListCountry from "../../Common/CustomListCountry/CustomListCountry"
import CustomListCategoryEvents from "../../Common/CustomListCategoryEvents/CustomListCategoryEvents"
import CustomListUser from "../../Common/CustomListUser/CustomListUser"
import CompagnyModal from "./CompagnyModal/CompagnyModal"

export const LIST_EVENTS = graphql(`
  query Events(
    $where: EventWhereInput
    $aggregateEventWhere2: EventWhereInput
    $orderBy: [EventOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    events(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      latitude
      longitude
      numberPeopleMax
      isFreeEvent
      isEventCommissionPaid
      title
      isValidateOnCompagny
      isPublish
      isValidate
      isOpenTicket
      descriptions
      endedDate
      startedDate
      startedTime
      isFromScrapping
      frequency
      commissionCategory
      endedTime
      isFree
      priority
      country {
        id
        name_fr
      }
      compagny {
        id
        name
      }
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
    aggregateEvent(where: $aggregateEventWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_EVENT = graphql(`
  mutation DeleteOneEvent($where: EventWhereUniqueInput!) {
    deleteOneEvent(where: $where) {
      id
      title
    }
  }
`)

const VALIDATE_EVENT = graphql(`
  mutation ValidateEvent($eventId: Float!, $isValidate: Boolean!) {
    validateEvent(eventId: $eventId, isValidate: $isValidate) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      isValidate
      isOpenTicket
      descriptions
      endedDate
      startedDate
      startedTime
      endedTime
      priority
      isFromScrapping
      frequency
      isValidateOnCompagny
      commissionCategory
      isFree
      country {
        id
        name_fr
      }
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const OPEN_TICKET_EVENT = graphql(`
  mutation UpdateOneEvent(
    $data: EventUpdateInput!
    $where: EventWhereUniqueInput!
  ) {
    updateOneEvent(data: $data, where: $where) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      isValidate
      isOpenTicket
      descriptions
      frequency
      commissionCategory
      isValidateOnCompagny
      endedDate
      startedDate
      startedTime
      isFromScrapping
      endedTime
      priority
      country {
        id
        name_fr
      }
      isFree
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const PUBLISH_EVENT = graphql(`
  mutation PublishEvent($eventId: Float!, $isPublish: Boolean!) {
    publishEvent(eventId: $eventId, isPublish: $isPublish) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      isValidate
      isOpenTicket
      descriptions
      endedDate
      startedDate
      startedTime
      endedTime
      isFree
      priority
      frequency
      isFromScrapping
      isValidateOnCompagny
      commissionCategory
      country {
        id
        name_fr
      }
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

export const DELETE_MANY = graphql(`
  mutation DeleteManyEvent($where: EventWhereInput) {
    deleteManyEvent(where: $where) {
      count
    }
  }
`)

const UPDATE_ORDER = graphql(`
  mutation UpdateOneEventPriority(
    $data: EventUpdateInput!
    $where: EventWhereUniqueInput!
  ) {
    updateOneEvent(data: $data, where: $where) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      isValidate
      isOpenTicket
      descriptions
      isFromScrapping
      endedDate
      startedDate
      startedTime
      frequency
      isValidateOnCompagny
      commissionCategory
      endedTime
      isFree
      priority
      country {
        id
        name_fr
      }
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const LIST_FILTER = graphql(`
  query FilterEvents {
    countries {
      id
      name_fr
    }
    findManyCategoryEvents {
      id
      name
    }
    users {
      id
      name
      lastName
    }
    compagnies {
      id
      name
    }
  }
`)

const OPEN_EVENT_COMPAGNY = graphql(`
  mutation UpdateOneEventCompagny(
    $data: EventUpdateInput!
    $where: EventWhereUniqueInput!
  ) {
    updateOneEvent(data: $data, where: $where) {
      id
      latitude
      longitude
      numberPeopleMax
      title
      isPublish
      isValidate
      isOpenTicket
      descriptions
      frequency
      commissionCategory
      isValidateOnCompagny
      endedDate
      startedDate
      isFromScrapping
      startedTime
      endedTime
      priority
      country {
        id
        name_fr
      }
      isFree
      mediators {
        id
        eventId
        invitationStatus
        user {
          id
          name
          lastName
          email
        }
      }
      categories {
        id
        name
      }
      creator {
        id
        name
        lastName
        email
        Country {
          name_fr
        }
        profile {
          id
          url
        }
      }
      place {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

const UpdatePriority = ({
  cbOk,
  id,
  priority,
  loading,
}: {
  id: number
  priority: number
  cbOk: (id: number, priority: number) => void
  loading: boolean
}) => {
  const [priorityValue, setPriorityValue] = useState(String(priority))

  const handleChange = useCallback(
    debounce((newPriority: string) => {
      cbOk(id, Number(newPriority))
    }, 500),
    [id, cbOk],
  )

  useEffect(() => {
    setPriorityValue(String(priority))
  }, [priority])

  const onPriorityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setPriorityValue(newValue)
    handleChange(newValue)
  }

  return (
    <TextField
      id="outlined-basic-priority"
      label=""
      size="small"
      variant="outlined"
      value={priorityValue}
      disabled={loading}
      type="number"
      onChange={onPriorityChange}
    />
  )
}

const ListEvent = () => {
  const [event, setEvent] = useState<Event | null>(null)
  const [open, setOpen] = useState(false)
  const [openCompagny, setOpenCompagny] = useState(false)
  const [openDescription, setOpenDescription] = useState(false)
  const [descriptions, setdescriptions] = useState("")
  const [user, setUser] = useState<User | null | undefined>(null)
  const [openModalUser, setOpenModalUser] = useState(false)
  const { page, size } = useAppSelector(selectEventPagination)
  const query = useAppSelector(selectEventQuery)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const [ids, setIds] = useState<number[]>([])
  const [compagnyId, setCompagnyId] = useState(0)

  const [country, setCountry] = useState<InfinityListItem>({
    label: "",
    value: "",
  })
  const [creator, setCreator] = useState<InfinityListItem>({
    label: "",
    value: "",
  })
  const [category, setCategory] = useState<InfinityListItem>({
    label: "",
    value: "",
  })

  const handleClose = () => setOpen(false)

  const handleOpenModalUser = (user?: User) => {
    setUser(user)
    setOpenModalUser(true)
  }

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const getValueFilter = (el: { name_fr: string; id: number }) => {
    return {
      label: el.name_fr,
      value: el.id.toString(),
      text: el.name_fr,
    }
  }

  const { loading: loadingFilter, data: dataFilter } = useQuery(LIST_FILTER)

  const [deleteMany] = useMutation(DELETE_MANY)
  const [updatePriority, { loading: loadingPriority }] =
    useMutation(UPDATE_ORDER)

  const getFilters: () => EventWhereInput = useCallback(() => {
    if (columnFilters.length) {
      return columnFilters.reduce(
        (
          accumulator: EventWhereInput,
          { id, value }: { id: string; value: any },
        ) => {
          switch (id) {
            case "Entreprise affiliée":
              accumulator = {
                ...accumulator,
                compagny: {
                  is: {
                    name: {
                      equals: value,
                    },
                  },
                },
              }
              break
            case "Pays":
              accumulator = {
                ...accumulator,
                countryId: {
                  equals: Number(value),
                },
              }
              break
            case "Catégorie":
              accumulator = {
                ...accumulator,
                categories: {
                  some: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "Organisateur":
              accumulator = {
                ...accumulator,
                creatorId: {
                  equals: Number(value),
                },
              }
              break
          }
          return accumulator
        },
        {},
      )
    }
    return {}
  }, [columnFilters])

  const handleUpdatePriority = (id: number, priority: number) => {
    if (!loadingPriority) {
      updatePriority({
        variables: {
          data: {
            priority: {
              set: Math.abs(priority),
            },
          },
          where: {
            id,
          },
        },
        update: (cache, { data }) => {
          updateCacheEvent({
            action: "update",
            cache,
            entryData: data?.updateOneEvent,
          })
        },
        onCompleted: () => {
          dispatch(
            setOpenSnackbar({
              message: "La priorité de l'événement a été modifié avec succès",
              status: "success",
            }),
          )
        },
        onError: (err) => {
          const message = getErrorsAsString(err)
          dispatch(setCloseAlert())
          dispatch(setOpenSnackbar({ message }))
        },
      })
    }
  }
  const handleValidDeleteMany = () => {
    dispatch(setLoadingDelete(true))
    deleteMany({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "La suppression a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleDeleteGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDeleteMany(),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ces évenements ?",
        isLoading: false,
      }),
    )
  }
  const customQuery = (key: string) => {
    return {
      OR: [
        {
          [key]: {
            contains: query,
            mode: QueryMode.Insensitive,
          },
        },
        {
          [key]: {
            search: query,
            mode: QueryMode.Insensitive,
          },
        },
      ],
    }
  }
  const queryName: InputMaybe<EventWhereInput> = useMemo(() => {
    return query
      ? {
          OR: [
            { ...customQuery("title") },
            {
              categories: {
                some: {
                  ...customQuery("name"),
                },
              },
            },
            {
              country: {
                is: {
                  ...customQuery("name_fr"),
                },
              },
            },
            {
              place: {
                is: {
                  ...customQuery("name"),
                },
              },
            },
            {
              creator: {
                is: {
                  OR: [
                    {
                      ...customQuery("name"),
                    },
                    {
                      ...customQuery("lastName"),
                    },
                    {
                      ...customQuery("email"),
                    },
                  ],
                },
              },
            },
          ],
        }
      : {}
  }, [query])

  const mobileFilter = useMemo(() => {
    return {
      ...(creator.value && {
        creatorId: {
          equals: +creator.value,
        },
      }),
      ...(category.value && {
        categories: {
          some: {
            id: {
              equals: +category.value,
            },
          },
        },
      }),
      ...(country.value && {
        countryId: {
          equals: +country.value,
        },
      }),
    }
  }, [country, category, creator])

  const { loading, data, refetch } = useQuery(LIST_EVENTS, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: [{ createdAt: SortOrder.Desc }],
      where: {
        ...queryName,
        ...getFilters(),
        ...mobileFilter,
      },
      aggregateEventWhere2: {
        ...queryName,
        ...getFilters(),
        ...mobileFilter,
      },
    },
  })

  const [deleteEvent] = useMutation(DELETE_EVENT)
  const [publishEvent] = useMutation(PUBLISH_EVENT)
  const [validateEvent] = useMutation(VALIDATE_EVENT)
  const [openEventTicket] = useMutation(OPEN_TICKET_EVENT)
  const [updateOpenCompagny] = useMutation(OPEN_EVENT_COMPAGNY)

  const onCompleted = () => {
    dispatch(
      setOpenSnackbar({
        message: "La modification a été faite avec succès",
        status: "success",
      }),
    )
    dispatch(setLoadingDelete(false))
    dispatch(setCloseAlert())
  }

  const onError = (err: ApolloError) => {
    const message = getErrorsAsString(err)
    dispatch(setCloseAlert())
    dispatch(setOpenSnackbar({ message }))
  }

  const handleTooglePublish = (id: number, isPublish: boolean) => {
    dispatch(setLoadingDelete(true))
    publishEvent({
      variables: {
        eventId: id,
        isPublish: !isPublish,
      },
      onCompleted: onCompleted,
      onError: onError,
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "update",
          cache,
          entryData: data?.publishEvent,
        })
      },
    })
  }

  const handleToogleOpenTicket = (
    id: number,
    isOpenTicket: boolean,
    commissionCategory: CommissionCategory,
  ) => {
    dispatch(setLoadingDelete(true))
    const isOpenTicketState = !isOpenTicket
    openEventTicket({
      variables: {
        data: {
          isOpenTicket: {
            set: isOpenTicketState,
          },
          commissionCategory: {
            set: isOpenTicketState
              ? commissionCategory
              : CommissionCategory.Seo,
          },
        },
        where: {
          id,
        },
      },
      onCompleted: onCompleted,
      onError: onError,
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "update",
          cache,
          entryData: data?.updateOneEvent,
        })
      },
    })
  }

  const handleToogleOpenCompagny = (id: number, isOpen: boolean) => {
    dispatch(setLoadingDelete(true))
    const isOpenState = !isOpen
    updateOpenCompagny({
      variables: {
        data: {
          isValidateOnCompagny: {
            set: isOpenState,
          },
        },
        where: {
          id,
        },
      },
      onCompleted: onCompleted,
      onError: onError,
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "update",
          cache,
          entryData: data?.updateOneEvent,
        })
      },
    })
  }

  const handleToogleValidate = (id: number, isValidate: boolean) => {
    dispatch(setLoadingDelete(true))
    validateEvent({
      variables: {
        eventId: id,
        isValidate: !isValidate,
      },
      onCompleted: onCompleted,
      onError: onError,
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "update",
          cache,
          entryData: data?.validateEvent,
        })
      },
    })
  }

  const handleShowAlertPublish = (
    id: number,
    isPublish: boolean,
    isFreeEvent: boolean,
    isValidate: boolean,
  ) => {
    if (!isValidate) {
      dispatch(
        setOpenSnackbar({
          message:
            "Cet événement doit être validé pour être publié, veuillez donc le valider avant de le publier.",
          status: "error",
        }),
      )
    } else if (!havePermissions(["event.publish"])) {
      dispatch(
        setOpenSnackbar({
          message:
            "Vous ne pouvez pas l'exonérer pour un événement. Veuillez contacter l'organisateur afin qu'il paie la commission de création de l'événement, afin que vous puissiez le publier.",
          status: "error",
        }),
      )
    } else {
      dispatch(
        setOpenAlert({
          handleValid: () => handleTooglePublish(id, isPublish),
          message:
            "Êtes-vous vraiment sûr de vouloir modifier la statut de publication de cet événement ?",
          isLoading: false,
        }),
      )
    }
  }

  const handleShowAlertOpenCompagny = (id: number, isOpen: boolean) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleToogleOpenCompagny(id, isOpen),
        message:
          "Êtes-vous sûr de vouloir modifier le statut d'ouverture de cet événement pour cette entreprise ?",
        isLoading: false,
      }),
    )
  }

  const handleShowAlertOpenTicket = (
    id: number,
    isOpenTicket: boolean,
    commissionCategory: CommissionCategory,
  ) => {
    dispatch(
      setOpenAlert({
        handleValid: () =>
          handleToogleOpenTicket(id, isOpenTicket, commissionCategory),
        message:
          "Êtes-vous vraiment sûr de vouloir modifier la statut de bietterie de cet événement ?",
        isLoading: false,
      }),
    )
  }

  const handleShowAlertValidate = (id: number, isValidate: boolean) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleToogleValidate(id, isValidate),
        message:
          "Êtes-vous vraiment sûr de vouloir modifier la statut de validation de cet événement ?",
        isLoading: false,
      }),
    )
  }

  const handleAddController = (event: Event) => {
    setEvent(event)
    setOpen(true)
  }

  const handleOpenModalCompagny = (id?: number | null) => {
    if (id) {
      setCompagnyId(id)
      setOpenCompagny(true)
    }
  }

  const columns = useMemo<MRT_ColumnDef<Event>[]>(
    () => [
      {
        accessorFn: (row) => (
          <>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(row.id)}
            >
              {row.id}
            </span>
          </>
        ),
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) =>
          row.compagny ? (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleOpenModalCompagny(row?.compagny?.id)}
            >
              <p>ID: {row?.compagny?.id}</p>
              <p>Nom: {row?.compagny?.name}</p>
            </span>
          ) : (
            "Pas définie"
          ),
        header: "Entreprise affiliée",
        enableClickToCopy: false,
        filterVariant: "autocomplete",
        filterSelectOptions: dataFilter?.compagnies.map((el) => ({
          label: el.name,
          value: el.name,
          text: el.name,
        })),
      },
      {
        accessorFn: (row) => {
          if (havePermissions(["event.publish"])) {
            return (
              <UpdatePriority
                cbOk={handleUpdatePriority}
                id={row.id}
                loading={loadingPriority}
                priority={row.priority}
              />
            )
          }
          return <p>{row.priority}</p>
        },
        header: "Priorité",
        enableEditing: true,
        size: 150,
        enableClickToCopy: true,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => getValue(row?.country?.name_fr),
        header: "Pays",
        filterVariant: "select",
        filterSelectOptions: dataFilter?.countries.map((el) =>
          getValueFilter(el),
        ),
      },
      {
        accessorFn: (row) => (
          <>
            <p
              onClick={() => handleOpenModalUser(row.creator)}
              style={{ cursor: "pointer" }}
            >
              ID: {row?.creator?.id}
            </p>
            <p
              onClick={() => handleOpenModalUser(row.creator)}
              style={{ cursor: "pointer" }}
            >
              Nom / Prénom: {row?.creator?.name || row?.creator?.lastName}
            </p>
          </>
        ),
        header: "Organisateur",
        filterVariant: "autocomplete",
        filterSelectOptions: dataFilter?.users.map((el) =>
          getValueFilter({
            ...el,
            name_fr: `${el.id}`,
          }),
        ),
      },
      {
        accessorFn: (row) =>
          row.categories && row.categories.length
            ? row.categories[0].name
            : null,
        header: "Catégorie",
        filterVariant: "select",
        filterSelectOptions: dataFilter?.findManyCategoryEvents.map((el) =>
          getValueFilter({ ...el, name_fr: el.name }),
        ),
      },
      {
        accessorFn: (row) => (
          <>
            <EventStatisticModal eventId={row.id} />
          </>
        ),
        header: "Statistiques",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <UserInEventModal eventId={row.id} />
          </>
        ),
        header: "Participants",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <CustomImage
              url={row?.coverImage?.url || ""}
              style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            />
          </>
        ),
        header: "Image de couverture",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isFromScrapping)}
              disabled
              id={row.id.toString()}
              handleChange={() => {}}
            />
          </>
        ),
        header: "Web scraping",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isOpenTicket)}
              handleChange={() => {
                if (havePermissions(["event.publish"])) {
                  handleShowAlertOpenTicket(
                    row.id,
                    Boolean(row.isOpenTicket),
                    row.commissionCategory,
                  )
                }
              }}
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de bietterie",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isValidateOnCompagny)}
              handleChange={() => {
                if (havePermissions(["event.publish"])) {
                  handleShowAlertOpenCompagny(
                    row.id,
                    Boolean(row.isValidateOnCompagny),
                  )
                }
              }}
              id={row.id.toString()}
              disabled={!Boolean(row.compagny)}
            />
          </>
        ),
        header: "Afficher dans LeHubs",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isPublish)}
              handleChange={() => {
                if (havePermissions(["event.publish"])) {
                  handleShowAlertPublish(
                    row.id,
                    Boolean(row.isPublish),
                    Boolean(row.isFreeEvent),
                    Boolean(row.isValidate),
                  )
                }
              }}
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de publication",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isValidate)}
              handleChange={() => {
                if (havePermissions(["event.validate"])) {
                  handleShowAlertValidate(row.id, Boolean(row.isValidate))
                }
              }}
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de validation",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>{row.isEventCommissionPaid ? "Payé" : "Non Payé"}</>
        ),
        header: "Commission Hub",
        enableEditing: false,
        enableColumnFilter: false,
      },

      {
        accessorFn: (row) => getValue(row?.place?.name),
        header: "Lieu",
        enableColumnFilter: false,
      },
      {
        accessorKey: "latitude",
        header: "Latitude",
        enableColumnFilter: false,
      },
      {
        accessorKey: "longitude",
        header: "Longitude",
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(row.id)}
            >
              {row.title}
            </span>
          </>
        ),
        header: "Nom",
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <span>{row.descriptions?.slice(0, 20)}...</span>
            <br />
            <span
              style={{ color: "green" }}
              onClick={() => {
                setdescriptions(row.descriptions || "")
                setOpenDescription(true)
              }}
            >
              Voir plus
            </span>
          </>
        ),
        header: "Déscription",
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => <>{row.isFreeEvent ? "Gratuit" : "Payant"}</>,
        header: "Type d'événement",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row?.city?.name_fr,
        header: "Ville",
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <Chip
              label="Ajouter"
              variant="outlined"
              onClick={() => {
                if (havePermissions(["event.addController"])) {
                  handleAddController(row)
                }
              }}
            />
          </>
        ),
        header: "Ajouter des controllers",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <p>
            {row.mediators
              .filter(
                (el) =>
                  el.invitationStatus === InvitationStatus.Valid && el.user,
              )
              .map((el) => el?.user?.id)
              .toString()}
          </p>
        ),
        header: "Contrôleurs validés",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <p>
            {row.mediators
              .filter(
                (el) =>
                  el.invitationStatus === InvitationStatus.Inprogress &&
                  el.user,
              )
              .map((el) => el?.user?.id)
              .toString()}
          </p>
        ),
        header: "Contrôleurs en attente",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => moment.utc(row?.startedDate).format("DD/MMM/YYYY"),
        header: "Date de début",
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => moment.utc(row?.startedTime).format("HH:mm"),
        header: "Heure de début",
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => moment.utc(row?.endedDate).format("DD/MMM/YYYY"),
        header: "Date de fin",
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => moment.utc(row?.endedTime).format("HH:mm"),
        header: "Heure de fin",
        enableColumnFilter: false,
      },
    ],
    [dataFilter],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationEvent(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteEvent({
      variables: {
        where: { id },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "L'évenement a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheEvent({
          action: "delete",
          cache,
          entryData: data?.deleteOneEvent,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer cet évenement ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) => {
    dispatch(setQueryEvent(value))
  }

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier un évenement"))
    navigate(`/event/edit/${id}`)
  }

  if (loadingFilter) return <Loader />

  return (
    <>
      <CustomTable
        columns={columns}
        data={data?.events || []}
        lableAddNew="Créer un nouveau évenement"
        rootLisName={"/event/add"}
        isLoading={loading}
        showProgressBars={loading}
        rowCount={data?.aggregateEvent._count?._all || 0}
        handleChangePagination={handleChangePagination}
        pageIndex={page}
        pageSize={size}
        key={page}
        searchValue={query}
        onChangeSearchValue={onChangeSearchValue}
        handleDelete={handleOpenDeleteDialog}
        handleEdit={handleEdit}
        handleSelected={handleSelected}
        handleDeleteGroup={handleDeleteGroup}
        selectedIds={ids}
        permissionChange="event.change"
        permissionCreate="event.create"
        permissionDelete="event.delete"
        permissionView="event.view"
        handleSelecteds={setIds}
        columnFilters={columnFilters}
        enableColumnFilters={true}
        setColumnFilters={setColumnFilters}
        childrenFilter={
          <Grid container spacing={1} marginBottom={"15px"}>
            <Grid item xs={12} sm={6}>
              <CustomListCountry
                getCountry={setCountry}
                label="Pays"
                countryDefault={country}
                required={false}
                isPlaceholder
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomListUser
                getUser={setCreator}
                label="Organisateur"
                userDefault={creator}
                required={false}
                isPlaceholder
                isID
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomListCategoryEvents
                label="Catégorie"
                categoryeventsDefault={category}
                getCategoryEvents={setCategory}
                required={false}
                isPlaceholder
              />
            </Grid>
          </Grid>
        }
      />
      {event && (
        <AddControllers event={event} handleClose={handleClose} open={open} />
      )}
      <UserModal
        handleClose={() => setOpenModalUser(false)}
        open={openModalUser}
        user={user}
      />
      <DescriptionModal
        handleClose={() => setOpenDescription(false)}
        open={openDescription}
        description={descriptions}
      />
      <CompagnyModal
        handleClose={() => setOpenCompagny(false)}
        open={openCompagny}
        compagnyId={compagnyId}
      />
    </>
  )
}

export default ListEvent
