import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"

const LIST_PRICING = graphql(`
  query ListPricing(
    $where: TicketPricingWhereInput
    $orderBy: [TicketPricingOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    ticketPricings(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name
    }
  }
`)

interface Props {
  getTicketPricing: (ticketPricing: any) => void
  ticketPricingDefault: InfinityListItem | InfinityListItem[]
  label?: string
  multiple?: boolean
  eventId?: number
}
const CustomListTicketPricing = ({
  getTicketPricing,
  ticketPricingDefault,
  label = "Ville",
  multiple = false,
  eventId,
}: Props) => {
  const [ticketPricing, setTicketPricing] = useState(ticketPricingDefault)

  const [queryticketPricing, setQueryticketPricing] = useState("")
  const [openticketPricing, setOpenticketPricing] = useState(false)
  const size = 30

  const queryName = useCallback(() => {
    const OrClause = {
      OR: [
        {
          name: { search: queryticketPricing, mode: QueryMode.Insensitive },
        },

        {
          name: {
            endsWith: queryticketPricing,
            mode: QueryMode.Insensitive,
          },
        },
        {
          name: {
            contains: queryticketPricing,
            mode: QueryMode.Insensitive,
          },
        },
        {
          name: {
            startsWith: queryticketPricing,
            mode: QueryMode.Insensitive,
          },
        },
      ],
    }
    return queryticketPricing ? OrClause : {}
  }, [queryticketPricing])

  const { loading, data, fetchMore } = useQuery(LIST_PRICING, {
    variables: {
      take: size,
      skip: 0,
      orderBy: { name: SortOrder.Asc },
      ...((queryticketPricing || eventId) && {
        where: {
          ...(queryticketPricing && { ...queryName() }),
          ...(eventId && {
            events: {
              some: {
                id: {
                  equals: eventId,
                },
              },
            },
          }),
        },
      }),
    },
  })

  const handleSearchCategory = (query: string) => {
    setQueryticketPricing(query)
  }

  const handleCloseCategory = () => {
    setOpenticketPricing(false)
  }
  const handleOpenticketPricing = async () => {
    setOpenticketPricing(true)
  }

  const onEndReachedCategory = () => {
    fetchMore({
      variables: {
        skip: data?.ticketPricings.length,
      },
    })
  }

  useEffect(() => {
    if (ticketPricing && ticketPricingDefault) {
      if (
        !Array.isArray(ticketPricing) &&
        !Array.isArray(ticketPricingDefault)
      ) {
        if (!ticketPricing?.value && ticketPricingDefault?.value) {
          setTicketPricing(ticketPricingDefault)
        }
      } else {
        setTicketPricing(ticketPricingDefault)
      }
    }
  }, [ticketPricing, ticketPricingDefault])

  const dataticketPricing = data?.ticketPricings

  const pricing =
    loading || !dataticketPricing
      ? []
      : dataticketPricing.map((el) => ({ label: el.name, value: el.id }))

  if (!pricing.length) return null
  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseCategory}
      handleOpen={handleOpenticketPricing}
      open={openticketPricing}
      defaultValue={ticketPricingDefault}
      keyName="label"
      disabled={!eventId}
      label={label}
      onSearch={handleSearchCategory}
      options={openticketPricing ? pricing : []}
      loading={loading}
      multiple={multiple}
      onChange={(value) => {
        if (value) {
          setTicketPricing(value)
          getTicketPricing(value)
        }
      }}
      query={queryticketPricing}
      value={ticketPricing}
      onEndReached={onEndReachedCategory}
      required={true}
    />
  )
}

export default CustomListTicketPricing
