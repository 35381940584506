import { useCallback, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Alert,
} from "@mui/material"
import { InfinityListItem } from "../../../types/InfinityListItem"
import CustomListEvent from "../../Common/CustomListEvent/CustomListEvent"
import CustomListUser from "../../Common/CustomListUser/CustomListUser"
import CustomListTicketPricing from "../../Common/CustomListPricing/CustomListPricing"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { useAppDispatch } from "../../../store/app/hooks"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"

interface Props {
  open: boolean
  handleClose: () => void
}

const TICKETS = graphql(`
  mutation GenerateTickets(
    $numberOfTicket: Int!
    $eventId: Int!
    $userId: Int
    $ticketPricingId: Int
    $email: String
  ) {
    generateTickets(
      numberOfTicket: $numberOfTicket
      eventId: $eventId
      userId: $userId
      ticketPricingId: $ticketPricingId
      email: $email
    ) {
      id
    }
  }
`)

const ERROR_MESSAGES = {
  EVENT_REQUIRED:
    "Veuillez sélectionner l'événement correspondant pour continuer !",
  USER_OR_EMAIL_REQUIRED:
    "Veuillez saisir un client existant ou une adresse e-mail.",
  TICKET_QUANTITY_REQUIRED:
    "Veuillez indiquer la quantité souhaitée pour les billets.",
}

const EventTicketModal = ({ handleClose, open }: Props) => {
  const dispatch = useAppDispatch()
  const [selectedEvent, setSelectedEvent] = useState<InfinityListItem>({
    label: "",
    value: "",
  })
  const [selectedPricing, setSelectedPricing] = useState<InfinityListItem>({
    label: "",
    value: "",
  })
  const [selectedUser, setSelectedUser] = useState<InfinityListItem>({
    label: "",
    value: "",
  })
  const [newUserEmail, setNewUserEmail] = useState("")
  const [numberOfTicket, setNumberOfTicket] = useState("1")
  const [error, setError] = useState<string | null>(null)

  const [generateTickets, { loading }] = useMutation(TICKETS)

  const validateForm = () => {
    if (!selectedEvent.value) {
      setError(ERROR_MESSAGES.EVENT_REQUIRED)
      return false
    }
    if (!selectedUser.value && !newUserEmail) {
      setError(ERROR_MESSAGES.USER_OR_EMAIL_REQUIRED)
      return false
    }
    if (!numberOfTicket || numberOfTicket === "0") {
      setError(ERROR_MESSAGES.TICKET_QUANTITY_REQUIRED)
      return false
    }
    setError(null)
    return true
  }

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()

      if (!validateForm()) return

      try {
        await generateTickets({
          variables: {
            eventId: +selectedEvent.value,
            numberOfTicket: +numberOfTicket,
            email: newUserEmail,
            ticketPricingId: selectedPricing.value
              ? +selectedPricing.value
              : null,
            userId: selectedUser.value ? +selectedUser.value : null,
          },
        })

        handleClose()
        dispatch(
          setOpenSnackbar({
            status: "success",
            message: "Les billets ont été générés avec succès !",
          }),
        )
      } catch (error: any) {
        handleClose()
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
      }
    },
    [
      selectedEvent,
      selectedUser,
      selectedPricing,
      newUserEmail,
      numberOfTicket,
    ],
  )

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={loading}
      onClose={() => !loading && handleClose()}
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { background: "dimgray" } }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle fontSize={"16px"}>Générer des billets</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box
            mb={4}
            sx={{
              backgroundColor: "white",
              color: "black",
              p: 1,
              borderRadius: 2,
            }}
          >
            <p>
              Veuillez noter que, dans le cas où le client ne dispose pas d’un
              compte, il est impératif d’utiliser son adresse e-mail comme
              identifiant principal.
            </p>
          </Box>

          <Box my={2}>
            <CustomListEvent
              getEvent={(event: InfinityListItem) => setSelectedEvent(event)}
              eventDefault={selectedEvent}
              label="Sélectionner l'événement"
              multiple={false}
              required={true}
            />
          </Box>

          {selectedEvent.value && (
            <Box my={2}>
              <CustomListTicketPricing
                getTicketPricing={(pricing: InfinityListItem) =>
                  setSelectedPricing(pricing)
                }
                ticketPricingDefault={selectedPricing}
                label="Sélectionner le type de billet"
                multiple={false}
                eventId={+selectedEvent.value}
              />
            </Box>
          )}

          <Box my={2}>
            <CustomListUser
              getUser={(user: InfinityListItem) => setSelectedUser(user)}
              userDefault={selectedUser}
              label="Sélectionner le client"
              multiple={false}
              required={false}
            />
          </Box>

          <Box my={2}>
            <TextField
              fullWidth
              label="Ajouter l'email de client"
              variant="outlined"
              value={newUserEmail}
              onChange={(e) => setNewUserEmail(e.target.value)}
              required={!selectedUser.value}
              type="email"
            />
          </Box>

          <Box my={2}>
            <TextField
              fullWidth
              label="Ajouter la quantité du billet"
              variant="outlined"
              type="number"
              value={numberOfTicket}
              onChange={(e) => setNumberOfTicket(e.target.value)}
              required={true}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <Button
            onClick={handleClose}
            disabled={loading}
            color="error"
            variant="contained"
          >
            Annuler
          </Button>
          <Button
            type="submit"
            color="success"
            variant="contained"
            disabled={loading}
          >
            {loading ? "Traitement..." : "Générer maintenant"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default EventTicketModal
