import { useCallback, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useMutation, useQuery } from "@apollo/client"
import {
  selectUserPagination,
  selectUserQuery,
  setUserPagination,
  setQuery,
} from "../../../store/features/user/userSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { SortOrder, User, Role, QueryMode } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { updateCacheAdmin } from "../../../caches/updateCacheAdmin"
import CustomImage from "../../Common/CustomImage/CustomImage"
import { getUserId } from "../../../utils/authToken"

export const LIST_ADMINS = graphql(`
  query AdminUsers(
    $where: UserWhereInput
    $aggregateUserWhere2: UserWhereInput
    $orderBy: [UserOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    users(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      phoneNumber
      email
      name
      lastName
      role
      _count {
        referrals
      }
      profile {
        url
        id
      }
      Country {
        id
        name_fr
      }
    }
    aggregateUser(where: $aggregateUserWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_ADMIN = graphql(`
  mutation DeleteOneAdmin($where: UserWhereUniqueInput!) {
    deleteOneUser(where: $where) {
      id
      phoneNumber
      email
      name
      lastName
      role
    }
  }
`)

export const DELETE_MANY = graphql(`
  mutation DeleteManyUser($where: UserWhereInput) {
    deleteManyUser(where: $where) {
      count
    }
  }
`)

const ListAdmin = () => {
  const { page, size } = useAppSelector(selectUserPagination)
  const query = useAppSelector(selectUserQuery)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userId = getUserId()
  const [ids, setIds] = useState<number[]>([])

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const [deleteMany] = useMutation(DELETE_MANY)

  const handleValidDeleteMany = () => {
    dispatch(setLoadingDelete(true))
    deleteMany({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "La suppression a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleDeleteGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDeleteMany(),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ces admins ?",
        isLoading: false,
      }),
    )
  }

  const queryName = useCallback(() => {
    if (!query) return {}

    const conditions: any = [
      { name: { contains: query, mode: QueryMode.Insensitive } },
      { lastName: { contains: query, mode: QueryMode.Insensitive } },
      { email: { contains: query, mode: QueryMode.Insensitive } },
    ]

    const numericQuery = Number(query)

    if (!isNaN(numericQuery)) {
      conditions.push({ id: { equals: numericQuery } })
    }

    return { OR: conditions }
  }, [query])

  const { loading, data, refetch } = useQuery(LIST_ADMINS, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: { name: SortOrder.Asc },
      aggregateUserWhere2: {
        role: {
          equals: Role.Admin,
        },
        ...queryName(),
      },
      where: {
        role: {
          equals: Role.Admin,
        },
        ...queryName(),
      },
    },
  })

  const [deleteAdmin] = useMutation(DELETE_ADMIN)

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorFn: (row) => (
          <>
            <CustomImage
              url={row?.profile?.url || ""}
              style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            />
          </>
        ),
        header: "Profile",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorKey: "name",
        header: "Nom",
        enableClickToCopy: true,
      },
      {
        accessorKey: "_count.referrals",
        header: "Nbs des parrainés",
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          row?.Country?.name_fr ? row?.Country?.name_fr : "Pas définie",
        header: "Pays",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "lastName",
        header: "Prénom",
        enableClickToCopy: true,
      },
      {
        accessorKey: "email",
        header: "email",
        enableClickToCopy: true,
      },
      {
        accessorKey: "phoneNumber",
        header: "Numéro de telephone",
        enableClickToCopy: true,
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setUserPagination(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteAdmin({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "L'admin a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheAdmin({
          action: "delete",
          cache,
          entryData: data?.deleteOneUser,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    if (id !== userId) {
      dispatch(
        setOpenAlert({
          handleValid: () => handleValidDelete(id),
          message: "Êtes-vous vraiment sûr de vouloir supprimer cet admin ?",
          isLoading: false,
        }),
      )
    } else {
      alert("Vous ne pouvez pas supprimer votre compte !!")
    }
  }

  const onChangeSearchValue = (value: string) => dispatch(setQuery(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier un admin"))
    navigate(`/admin/edit/${id}`)
  }

  

  return (
    <CustomTable
      columns={columns}
      data={data?.users || []}
      lableAddNew="Créer un nouveau admin"
      rootLisName={"/admin/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateUser._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={handleOpenDeleteDialog}
      handleEdit={handleEdit}
      handleSelected={handleSelected}
      handleDeleteGroup={handleDeleteGroup}
      selectedIds={ids}
      permissionChange="admin.change"
      permissionCreate="admin.create"
      permissionDelete="admin.delete"
      permissionView="admin.view"
    />
  )
}

export default ListAdmin
