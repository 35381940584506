import { useCallback, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useMutation, useQuery } from "@apollo/client"
import {
  selectPlacePagination,
  setQueryPlace,
  setPaginationPlace,
  selectPlaceQuery,
} from "../../../store/features/place/placeSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import {
  SortOrder,
  Place,
  QueryMode,
  InputMaybe,
  PlaceWhereInput,
} from "../../../gql/graphql"
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import CustomImage from "../../Common/CustomImage/CustomImage"
import SwitchButton from "../../Common/SwitchButton/SwitchButton"
import { updateCachePlace } from "../../../caches/updateCachePlace"
import { havePermissions } from "../../../utils/permissions"
import DescriptionModal from "../../Event/ListEvent/DescriptionModal/DescriptionModal"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { Grid } from "@mui/material"
import CustomListCountry from "../../Common/CustomListCountry/CustomListCountry"
import CustomListUser from "../../Common/CustomListUser/CustomListUser"
import CustomListCategoryEvents from "../../Common/CustomListCategoryEvents/CustomListCategoryEvents"

export const LIST_PLACES = graphql(`
  query Places(
    $where: PlaceWhereInput
    $aggregatePlaceWhere2: PlaceWhereInput
    $orderBy: [PlaceOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    places(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name
      latitude
      longitude
      isPublish
      descriptions
      categories {
        id
        name
      }
      city {
        name_fr
        id
        country {
          id
          name_fr
        }
      }
      coverImage {
        url
        id
      }
    }
    aggregatePlace(where: $aggregatePlaceWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_PLACE = graphql(`
  mutation DeleteOnePlace($where: PlaceWhereUniqueInput!) {
    deleteOnePlace(where: $where) {
      id
      name
    }
  }
`)

export const UPDATE_PLACE = graphql(`
  mutation UpdateOnePlace(
    $data: PlaceUpdateInput!
    $where: PlaceWhereUniqueInput!
  ) {
    updateOnePlace(data: $data, where: $where) {
      id
      latitude
      longitude
      descriptions
      name
      isPublish
      categories {
        id
        name
      }
      city {
        id
        name_fr
        country {
          id
          name_fr
        }
      }
      coverImage {
        id
        url
      }
    }
  }
`)

export const DELETE_MANY = graphql(`
  mutation DeleteManyPlace($where: PlaceWhereInput) {
    deleteManyPlace(where: $where) {
      count
    }
  }
`)

const LIST_FILTER = graphql(`
  query FilterPlaces {
    countries {
      id
      name_fr
    }
    categoryPlaces {
      id
      name
    }
    users {
      id
      name
      lastName
    }
  }
`)

const ListPlace = () => {
  const { page, size } = useAppSelector(selectPlacePagination)
  const query = useAppSelector(selectPlaceQuery)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ids, setIds] = useState<number[]>([])
  const [openDescription, setOpenDescription] = useState(false)
  const [descriptions, setdescriptions] = useState("")

  const [country, setCountry] = useState<InfinityListItem>({
    label: "",
    value: "",
  })
  const [creator, setCreator] = useState<InfinityListItem>({
    label: "",
    value: "",
  })
  const [category, setCategory] = useState<InfinityListItem>({
    label: "",
    value: "",
  })

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const [deleteMany] = useMutation(DELETE_MANY)

  const handleValidDeleteMany = () => {
    dispatch(setLoadingDelete(true))
    deleteMany({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "La suppression a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleDeleteGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDeleteMany(),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ces lieux ?",
        isLoading: false,
      }),
    )
  }

  const customQuery = useCallback(
    (key: string) => {
      return {
        OR: [
          {
            [key]: {
              contains: query,
              mode: QueryMode.Insensitive,
            },
          },
          {
            [key]: {
              search: query,
              mode: QueryMode.Insensitive,
            },
          },
        ],
      }
    },
    [query],
  )

  const queryName: InputMaybe<PlaceWhereInput> = useMemo(() => {
    return query
      ? {
          OR: [
            {
              categories: {
                some: {
                  ...customQuery("name"),
                },
              },
            },
            { ...customQuery("name") },
            {
              ...customQuery("descriptions"),
            },
            {
              creator: {
                is: {
                  OR: [
                    {
                      ...customQuery("name"),
                    },
                    {
                      ...customQuery("lastName"),
                    },
                    {
                      ...customQuery("email"),
                    },
                  ],
                },
              },
            },

            {
              city: {
                is: {
                  ...customQuery("name"),
                },
              },
            },
          ],
        }
      : {}
  }, [query, customQuery])

  const [deletePlace] = useMutation(DELETE_PLACE)
  const [updatePlace] = useMutation(UPDATE_PLACE)
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])

  const getValueFilter = (el: { name_fr: string; id: number }) => {
    return {
      label: el.name_fr,
      value: el.id.toString(),
      text: el.name_fr,
    }
  }

  const { loading: loadingFilter, data: dataFilter } = useQuery(LIST_FILTER)

  const getFilters: () => PlaceWhereInput = useCallback(() => {
    if (columnFilters.length) {
      return columnFilters.reduce(
        (
          accumulator: PlaceWhereInput,
          { id, value }: { id: string; value: any },
        ) => {
          switch (id) {
            case "Pays":
              accumulator = {
                ...accumulator,
                city: {
                  is: {
                    country: {
                      is: {
                        id: { equals: Number(value) },
                      },
                    },
                  },
                },
              }
              break
            case "Catégorie":
              accumulator = {
                ...accumulator,
                categories: {
                  some: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "Créateur":
              accumulator = {
                ...accumulator,
                creatorId: {
                  equals: Number(value),
                },
              }
              break
          }
          return accumulator
        },
        {},
      )
    }
    return {}
  }, [columnFilters])

  const mobileFilter = useMemo(() => {
    return {
      ...(creator.value && {
        creatorId: {
          equals: +creator.value,
        },
      }),
      ...(category.value && {
        categories: {
          some: {
            id: {
              equals: +category.value,
            },
          },
        },
      }),
      ...(country.value && {
        city: {
          is: {
            country: {
              is: {
                id: { equals: +country.value },
              },
            },
          },
        },
      }),
    }
  }, [country, category, creator])

  const variables = useMemo(() => {
    return {
      take: size,
      skip: page * size,
      orderBy: [{ createdAt: SortOrder.Desc }],
      where: {
        ...queryName,
        ...getFilters(),
        ...mobileFilter,
      },
      aggregatePlaceWhere2: {
        ...queryName,
        ...getFilters(),
        ...mobileFilter,
      },
    }
  }, [page, queryName, size, columnFilters, mobileFilter])

  const { loading, data, refetch } = useQuery(LIST_PLACES, {
    variables,
  })

  const handleTooglePublish = (id: number, isPublish: boolean) => {
    dispatch(setLoadingDelete(true))
    updatePlace({
      variables: {
        where: {
          id,
        },
        data: {
          isPublish: {
            set: !isPublish,
          },
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "La modification a été faite avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCachePlace({
          action: "update",
          cache,
          entryData: data?.updateOnePlace,
        })
      },
    })
  }

  const handleShowAlertPublish = (id: number, isPublish: boolean) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleTooglePublish(id, isPublish),
        message:
          "Êtes-vous vraiment sûr de vouloir modifier la statut de publication de ce lieu ?",
        isLoading: false,
      }),
    )
  }

  const columns = useMemo<MRT_ColumnDef<Place>[]>(
    () => [
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isPublish)}
              handleChange={() => {
                if (havePermissions(["place.publish"])) {
                  handleShowAlertPublish(row.id, Boolean(row.isPublish))
                }
              }}
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de publication",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: "name",
        header: "Nom",
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) =>
          row.categories && row.categories.length
            ? row.categories.map((el) => el.name).toString()
            : null,
        header: "Catégorie",
        filterVariant: "select",
        filterSelectOptions: dataFilter?.categoryPlaces.map((el) =>
          getValueFilter({ ...el, name_fr: el.name }),
        ),
      },
      {
        accessorFn: (row) => row?.city?.country?.name_fr,
        header: "Pays",
        filterVariant: "select",
        filterSelectOptions: dataFilter?.countries.map((el) =>
          getValueFilter(el),
        ),
      },
      {
        accessorFn: (row) => row?.city?.country?.name_fr,
        header: "Créateur",
        filterVariant: "select",
        filterSelectOptions: dataFilter?.users.map((el) =>
          getValueFilter({
            ...el,
            name_fr: `${el.lastName || el.name || el.id}`,
          }),
        ),
      },
      {
        accessorFn: (row) => (
          <>
            <CustomImage
              url={row?.coverImage?.url || ""}
              style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            />
          </>
        ),
        header: "Image de couverture",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row?.city?.name_fr,
        header: "Ville",
        enableColumnFilter: false,
      },
      {
        accessorKey: "latitude",
        header: "Latitude",
        enableColumnFilter: false,
      },
      {
        accessorKey: "longitude",
        header: "Longitude",
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <span>{row.descriptions?.slice(0, 20)}...</span>
            <br />
            <span
              style={{ color: "green" }}
              onClick={() => {
                setdescriptions(row.descriptions || "")
                setOpenDescription(true)
              }}
            >
              Voir plus
            </span>
          </>
        ),
        header: "Déscription",
        enableColumnFilter: false,
      },
    ],
    [dataFilter],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationPlace(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deletePlace({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "Le lieu a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCachePlace({
          action: "delete",
          cache,
          entryData: data?.deleteOnePlace,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ce lieu ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) => dispatch(setQueryPlace(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier un lieu"))
    navigate(`/place/edit/${id}`)
  }

  if (loadingFilter) return <Loader />

  return (
    <>
      <CustomTable
        columns={columns}
        data={data?.places || []}
        lableAddNew="Créer un nouveau lieu"
        rootLisName={"/place/add"}
        isLoading={loading}
        showProgressBars={loading}
        rowCount={data?.aggregatePlace._count?._all || 0}
        handleChangePagination={handleChangePagination}
        pageIndex={page}
        pageSize={size}
        key={page}
        searchValue={query}
        onChangeSearchValue={onChangeSearchValue}
        handleDelete={handleOpenDeleteDialog}
        handleEdit={handleEdit}
        handleSelected={handleSelected}
        handleDeleteGroup={handleDeleteGroup}
        selectedIds={ids}
        permissionChange="place.change"
        permissionCreate="place.create"
        permissionDelete="place.delete"
        permissionView="place.view"
        handleSelecteds={setIds}
        columnFilters={columnFilters}
        enableColumnFilters={true}
        setColumnFilters={setColumnFilters}
        childrenFilter={
          <Grid container spacing={1} marginBottom={"15px"}>
            <Grid item xs={12} sm={6}>
              <CustomListCountry
                getCountry={setCountry}
                label="Pays"
                countryDefault={country}
                required={false}
                isPlaceholder
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomListUser
                getUser={setCreator}
                label="Créateur"
                userDefault={creator}
                required={false}
                isPlaceholder
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomListCategoryEvents
                label="Catégorie"
                categoryeventsDefault={category}
                getCategoryEvents={setCategory}
                required={false}
                isPlaceholder
              />
            </Grid>
          </Grid>
        }
      />
      <DescriptionModal
        handleClose={() => setOpenDescription(false)}
        open={openDescription}
        description={descriptions}
      />
    </>
  )
}

export default ListPlace
