import React, { ReactNode } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material"
import {
  Download as DownloadIcon,
  Close as CloseIcon,
} from "@mui/icons-material"

interface ImageDownloadModalProps {
  imageUrl: string
  open: boolean
  onClose: () => void
  title?: string
  actions?: ReactNode
  isUndimiss?: boolean
}

const ImageDownloadModal: React.FC<ImageDownloadModalProps> = ({
  imageUrl,
  open,
  onClose,
  title,
  actions,
  isUndimiss,
}) => {
  const handleDownload = () => {
    const link = document.createElement("a")
    link.href = imageUrl
    link.download = "downloaded-image"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isUndimiss) {
          onClose()
        }
      }}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "white",
        },
      }}
    >
      <DialogTitle
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <h4 style={{ color: "black" }}>{title || "Télécharger le billet"}</h4>
        <IconButton
          onClick={() => {
            if (!isUndimiss) {
              onClose()
            }
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={imageUrl}
            alt="Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            if (!isUndimiss) {
              onClose()
            }
          }}
        >
          Fermer
        </Button>
        {actions}
        <Button
          onClick={() => {
            if (!isUndimiss) {
              handleDownload()
            }
          }}
          color="success"
          variant="contained"
          startIcon={<DownloadIcon />}
        >
          Télécharger
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImageDownloadModal
