import { useCallback, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useMutation, useQuery } from "@apollo/client"
import {
  selectNoticeEventPagination,
  setQueryNoticeEvent,
  setPaginationNoticeEvent,
  selectNoticeEventQuery,
} from "../../../store/features/noticeEvent/noticeEventSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { SortOrder, NoticeEvent, QueryMode } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import SwitchButton from "../../Common/SwitchButton/SwitchButton"
import { updateCacheNoticeEvent } from "../../../caches/updateCacheNoticeEvent"
import { havePermissions } from "../../../utils/permissions"

export const LIST_NOTICEEVENTS = graphql(`
  query NoticeEvents(
    $where: NoticeEventWhereInput
    $aggregateNoticeEventWhere2: NoticeEventWhereInput
    $orderBy: [NoticeEventOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    noticeEvents(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      note
      isPublish
      description
      createdAt
      creator {
        id
        name
        lastName
      }
      event {
        id
        title
      }
    }
    aggregateNoticeEvent(where: $aggregateNoticeEventWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_NOTICEEVENT = graphql(`
  mutation DeleteOneNoticeEvent($where: NoticeEventWhereUniqueInput!) {
    deleteOneNoticeEvent(where: $where) {
      id
    }
  }
`)

export const UPDATE_NOTICEEVENT = graphql(`
  mutation UpdateOneNoticeEvent(
    $data: NoticeEventUpdateInput!
    $where: NoticeEventWhereUniqueInput!
  ) {
    updateOneNoticeEvent(data: $data, where: $where) {
      id
      note
      isPublish
      description
      createdAt
      creator {
        id
        name
        lastName
      }
      event {
        id
        title
      }
    }
  }
`)

export const DELETE_MANY = graphql(`
  mutation DeleteManyNoticeEvent($where: NoticeEventWhereInput) {
    deleteManyNoticeEvent(where: $where) {
      count
    }
  }
`)

const ListNoticeEvent = () => {
  const { page, size } = useAppSelector(selectNoticeEventPagination)
  const query = useAppSelector(selectNoticeEventQuery)
  const dispatch = useAppDispatch()
  const [ids, setIds] = useState<number[]>([])

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const [deleteMany] = useMutation(DELETE_MANY)

  const handleValidDeleteMany = () => {
    dispatch(setLoadingDelete(true))
    deleteMany({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "La suppression a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleDeleteGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDeleteMany(),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ces avis ?",
        isLoading: false,
      }),
    )
  }

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            {
              event: {
                is: {
                  title: { contains: query, mode: QueryMode.Insensitive },
                },
              },
            },
            {
              creator: {
                is: {
                  OR: [
                    { name: { contains: query, mode: QueryMode.Insensitive } },
                    {
                      lastName: {
                        contains: query,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
            },
          ],
        }
      : {}
  }, [query])

  const { loading, data, refetch } = useQuery(LIST_NOTICEEVENTS, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: [{ createdAt: SortOrder.Desc }, { eventId: SortOrder.Desc }],
      where: {
        ...queryName(),
      },
    },
  })

  const [deleteNoticeEvent] = useMutation(DELETE_NOTICEEVENT)
  const [updateNoticeEvent] = useMutation(UPDATE_NOTICEEVENT)

  const handleTooglePublish = (id: number, isPublish: boolean) => {
    dispatch(setLoadingDelete(true))
    updateNoticeEvent({
      variables: {
        where: {
          id,
        },
        data: {
          isPublish: {
            set: !isPublish,
          },
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "La modification a été faite avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheNoticeEvent({
          action: "update",
          cache,
          entryData: data?.updateOneNoticeEvent,
        })
      },
    })
  }

  const handleShowAlertPublish = (id: number, isPublish: boolean) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleTooglePublish(id, isPublish),
        message:
          "Êtes-vous vraiment sûr de vouloir modifier la statut de publication de cet avis ?",
        isLoading: false,
      }),
    )
  }

  const columns = useMemo<MRT_ColumnDef<NoticeEvent>[]>(
    () => [
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isPublish)}
              handleChange={() => {
                if (havePermissions(["noticeEvent.publish"])) {
                  handleShowAlertPublish(row.id, Boolean(row.isPublish))
                }
              }}
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de publication",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorKey: "note",
        header: "Avis",
      },
      {
        accessorKey: "description",
        header: "Commentaire",
      },
      {
        accessorFn: (row) => row.creator.id,
        header: "ID de créateur",
      },
      {
        accessorFn: (row) => row.creator.lastName || row?.creator?.name,
        header: "Nom de Créateur",
      },
      {
        accessorFn: (row) => row.event.id,
        header: "ID d'évenement",
      },
      {
        accessorFn: (row) => row.event.title,
        header: "Nom d'évenement",
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationNoticeEvent(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteNoticeEvent({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "L'avis a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheNoticeEvent({
          action: "delete",
          cache,
          entryData: data?.deleteOneNoticeEvent,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer cet avis ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(setQueryNoticeEvent(value))

  return (
    <CustomTable
      columns={columns}
      data={data?.noticeEvents || []}
      lableAddNew=""
      rootLisName={""}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateNoticeEvent._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={handleOpenDeleteDialog}
      handleSelected={handleSelected}
      handleDeleteGroup={handleDeleteGroup}
      selectedIds={ids}
      permissionChange="noticeEvent.change"
      permissionCreate="noticeEvent.create"
      permissionDelete="noticeEvent.delete"
      permissionView="noticeEvent.view"
      handleSelecteds={setIds}
    />
  )
}

export default ListNoticeEvent
