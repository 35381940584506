import { useCallback, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import { useQuery } from "@apollo/client"
import {
  selectUserPagination,
  selectUserQuery,
  setUserPagination,
  setQuery,
} from "../../../store/features/user/userSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { SortOrder, User, QueryMode } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import CustomImage from "../../Common/CustomImage/CustomImage"

export const LIST_ADMINS = graphql(`
  query Admins(
    $where: UserWhereInput
    $aggregateUserWhere2: UserWhereInput
    $orderBy: [UserOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    users(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      phoneNumber
      email
      name
      lastName
      role
      profile {
        url
        id
      }
      Country {
        id
        name_fr
      }
      _count {
        referrals
        events
        place
      }
    }
    aggregateUser(where: $aggregateUserWhere2) {
      _count {
        _all
      }
    }
  }
`)

const ListAdmin = () => {
  const { page, size } = useAppSelector(selectUserPagination)
  const query = useAppSelector(selectUserQuery)
  const dispatch = useAppDispatch()

  const queryName = useCallback(() => {
    if (!query) return {}

    const conditions: any = [
      { name: { contains: query, mode: QueryMode.Insensitive } },
      { lastName: { contains: query, mode: QueryMode.Insensitive } },
      { email: { contains: query, mode: QueryMode.Insensitive } },
    ]

    const numericQuery = Number(query)

    if (!isNaN(numericQuery)) {
      conditions.push({ id: { equals: numericQuery } })
    }

    return { OR: conditions }
  }, [query])

  const { loading, data } = useQuery(LIST_ADMINS, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: { name: SortOrder.Asc },
      where: {
        ...queryName(),
      },
    },
  })

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorFn: (row) => (
          <>
            <CustomImage
              url={row?.profile?.url || ""}
              style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            />
          </>
        ),
        header: "Profile",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorKey: "name",
        header: "Nom",
        enableClickToCopy: true,
      },
      {
        accessorKey: "_count.referrals",
        header: "Nbs des parrainés",
      },
      {
        accessorKey: "_count.place",
        header: "Nbs des lieux",
      },
      {
        accessorKey: "_count.events",
        header: "Nbs des événements",
      },
      {
        accessorFn: (row) =>
          row?.Country?.name_fr ? row?.Country?.name_fr : "Pas définie",
        header: "Pays",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "lastName",
        header: "Prénom",
        enableClickToCopy: true,
      },
      {
        accessorKey: "email",
        header: "email",
        enableClickToCopy: true,
      },
      {
        accessorKey: "phoneNumber",
        header: "Numéro de telephone",
        enableClickToCopy: true,
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setUserPagination(pagination))

  const onChangeSearchValue = (value: string) => dispatch(setQuery(value))

  

  return (
    <CustomTable
      columns={columns}
      data={data?.users || []}
      lableAddNew=""
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateUser._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      isHaveAction={false}
      permissionChange="customer.change"
      permissionCreate="customer.create"
      permissionDelete="customer.delete"
      permissionView="customer.view"
    />
  )
}

export default ListAdmin
