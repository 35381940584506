import moment, { Moment } from "moment-timezone"

const useTime = () => {
  const formatInitialDateTime = (initialDate: string | Date | Moment) => {
    // Convertir la date initiale en objet Date
    const initialDateObj = getDate({ date: initialDate, isDate: true }) as Date

    // Formater la date pour input[type="date"] (YYYY-MM-DD)
    const formattedDate = moment.utc(initialDateObj).format("YYYY-MM-DD")

    // Formater l'heure pour input[type="time"] (HH:mm)
    const formattedTime = moment.utc(initialDateObj).format("HH:mm")

    return { formattedDate, formattedTime }
  }

  const getDate = ({
    date,
    format,
    isDate,
    isIso,
  }: {
    date: string | Date | Moment
    format?: string
    isDate?: boolean
    isIso?: boolean
  }) => {
    if (isDate) {
      return moment.utc(date).toDate() // Retourne un objet Date
    }
    if (isIso) {
      return moment.utc(date).toISOString() // Retourne une chaîne ISO
    }
    return moment.utc(date).format(format) // Retourne une chaîne formatée
  }

  return {
    getDate,
    formatInitialDateTime,
  }
}

export default useTime
