import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"

const LIST_CATEGORYEVENTSS = graphql(`
  query ListCategoryEvents(
    $where: CategoryEventsWhereInput
    $orderBy: [CategoryEventsOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    findManyCategoryEvents(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      name
    }
  }
`)

interface Props {
  getCategoryEvents: (categoryevents: any) => void
  categoryeventsDefault: InfinityListItem | InfinityListItem[]
  label?: string
  required?: boolean
  multiple?: boolean
  isPlaceholder?: boolean
}
const CustomListCategoryEvents = ({
  getCategoryEvents,
  categoryeventsDefault,
  label = "Catégorie",
  required = true,
  multiple = false,
  isPlaceholder,
}: Props) => {
  const [categoryevents, setCategoryEvents] = useState(categoryeventsDefault)

  const [queryCategoryEvents, setQueryCategoryEvents] = useState("")
  const [openCategoryEvents, setOpenCategoryEvents] = useState(false)
  const size = 500

  const queryName = useCallback(() => {
    const OrClause = {
      OR: [
        { name: { search: queryCategoryEvents, mode: QueryMode.Insensitive } },
        {
          name: { endsWith: queryCategoryEvents, mode: QueryMode.Insensitive },
        },
        {
          name: { contains: queryCategoryEvents, mode: QueryMode.Insensitive },
        },
        {
          name: {
            startsWith: queryCategoryEvents,
            mode: QueryMode.Insensitive,
          },
        },
      ],
    }
    return queryCategoryEvents ? OrClause : {}
  }, [queryCategoryEvents])

  const { loading, data, fetchMore } = useQuery(LIST_CATEGORYEVENTSS, {
    variables: {
      // take: size,
      // skip: 0,
      orderBy: { name: SortOrder.Asc },
      ...(queryCategoryEvents && {
        where: {
          ...queryName(),
        },
      }),
    },
  })

  const handleSearchCategory = (query: string) => {
    setQueryCategoryEvents(query)
  }

  const handleCloseCategory = () => {
    setOpenCategoryEvents(false)
  }
  const handleOpenCategoryEvents = async () => {
    setOpenCategoryEvents(true)
  }

  const onEndReachedCategory = () => {
    fetchMore({
      variables: {
        skip: data?.findManyCategoryEvents.length,
      },
    })
  }

  useEffect(() => {
    if (categoryevents && categoryeventsDefault) {
      if (
        !Array.isArray(categoryevents) &&
        !Array.isArray(categoryeventsDefault)
      ) {
        if (!categoryevents?.value && categoryeventsDefault?.value) {
          setCategoryEvents(categoryeventsDefault)
        }
      } else {
        setCategoryEvents(categoryeventsDefault)
      }
    }
  }, [categoryevents, categoryeventsDefault])

  const dataCategoryEvents = data?.findManyCategoryEvents

  const categoryeventss =
    loading || !dataCategoryEvents
      ? []
      : dataCategoryEvents.map((el) => ({ label: el.name, value: el.id }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseCategory}
      handleOpen={handleOpenCategoryEvents}
      open={openCategoryEvents}
      defaultValue={categoryeventsDefault}
      keyName="label"
      label={label}
      onSearch={handleSearchCategory}
      options={openCategoryEvents ? categoryeventss : []}
      loading={loading}
      multiple={multiple}
      isPlaceholder={isPlaceholder}
      onChange={(value) => {
        if (value) {
          setCategoryEvents(value)
          getCategoryEvents(value)
        }
      }}
      query={queryCategoryEvents}
      value={categoryevents}
      onEndReached={onEndReachedCategory}
      required={required}
    />
  )
}

export default CustomListCategoryEvents
