import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface EventState {
  pagination: Pagination
  query: string
}

const initialState: EventState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setPaginationEvent: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setQueryEvent: (state, action: PayloadAction<string>) => {
      state.pagination = {
        page: 0,
        size: 10,
      }
      state.query = action.payload
    },
  },
})

export const selectEventPagination = (state: RootState) =>
  state.event.pagination
export const selectEventQuery = (state: RootState) => state.event.query
export const { setPaginationEvent, setQueryEvent } = eventSlice.actions
export default eventSlice.reducer
