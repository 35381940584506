import { useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useMutation, useQuery } from "@apollo/client"
import {
  selectCommissionPagination,
  setQueryCommission,
  setPaginationCommission,
  selectCommissionQuery,
} from "../../../store/features/commission/commissionSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import {
  SortOrder,
  QueryMode,
  CommissionWhereInput,
  Commission,
  Frequency,
  CommissionCategory,
} from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { updateCacheCommission } from "../../../caches/updateCacheCommission"

export const LIST_COMMISSIONS = graphql(`
  query Commissions(
    $where: CommissionWhereInput
    $aggregateCommissionWhere2: CommissionWhereInput
    $orderBy: [CommissionOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    commissions(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      frequency
      price
      type
      createdAt
      updatedAt
      category
      description_dj
      description_en
      description_fr
      description_mg
      description_wl
      title_dj
      title_en
      title_fr
      title_mg
      title_wl
      countries {
        id
        name_fr
        currency
      }
    }
    aggregateCommission(where: $aggregateCommissionWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_COMMISSIONS = graphql(`
  mutation DeleteOneCommission($where: CommissionWhereUniqueInput!) {
    deleteOneCommission(where: $where) {
      id
      frequency
      price
      type
      createdAt
      updatedAt
      category
      countries {
        id
        name_fr
        currency
      }
    }
  }
`)

export const DELETE_MANY = graphql(`
  mutation DeleteManyCommission($where: CommissionWhereInput) {
    deleteManyCommission(where: $where) {
      count
    }
  }
`)

const ListCommission = () => {
  const { page, size } = useAppSelector(selectCommissionPagination)
  const query = useAppSelector(selectCommissionQuery)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ids, setIds] = useState<number[]>([])

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const [deleteMany] = useMutation(DELETE_MANY)

  const handleValidDeleteMany = () => {
    dispatch(setLoadingDelete(true))
    deleteMany({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "La suppression a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleDeleteGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDeleteMany(),
        message:
          "Êtes-vous vraiment sûr de vouloir supprimer ces commissions fixes ?",
        isLoading: false,
      }),
    )
  }

  const queryName: CommissionWhereInput = useMemo(() => {
    return query
      ? {
          OR: [
            {
              countries: {
                some: {
                  name_fr: {
                    contains: query,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
            { price: { equals: +query } },
          ],
        }
      : {}
  }, [query])

  const { loading, data, refetch } = useQuery(LIST_COMMISSIONS, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: [{ type: SortOrder.Asc }],
      where: {
        ...queryName,
      },
    },
  })

  const [deleteCommissions] = useMutation(DELETE_COMMISSIONS)

  const columns = useMemo<MRT_ColumnDef<Commission>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "price",
        header: "Commission fixe",
      },
      {
        accessorFn: (row) =>
          row?.countries.map((el) => el.currency).toLocaleString(),
        header: "Devise",
      },
      {
        accessorFn: (row) =>
          row.category == CommissionCategory.Free
            ? "Gratuit"
            : row.category === CommissionCategory.Paid
            ? "Payant"
            : "Référencement",
        header: "Nature d'événement",
      },
      {
        accessorFn: (row) =>
          row?.countries.map((el) => el.name_fr).toLocaleString(),
        header: "Pays",
      },
      {
        accessorFn: (row) =>
          row?.frequency === Frequency.Ponctuel
            ? "Ponctuelle"
            : row?.frequency === Frequency.Once
            ? "Faible(5 jours)"
            : row?.frequency === Frequency.Medium
            ? "Moyenne(15 jours)"
            : row?.frequency === Frequency.High
            ? "Élevé(30 jours)"
            : row?.frequency === Frequency.Threemonths
            ? "3 mois"
            : row?.frequency === Frequency.Sixmonths
            ? "6 mois"
            : "1 an",
        header: "Visibilité d'événement",
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationCommission(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteCommissions({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "La commission fixe a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheCommission({
          action: "delete",
          cache,
          entryData: data?.deleteOneCommission,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message:
          "Êtes-vous vraiment sûr de vouloir supprimer cette commission fixe ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(setQueryCommission(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier une commission fixe"))
    navigate(`/commission/edit/${id}`)
  }

  

  return (
    <CustomTable
      columns={columns}
      data={data?.commissions || []}
      lableAddNew="Créer une nouvelle commission fixe"
      rootLisName={"/commission/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateCommission._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={handleOpenDeleteDialog}
      handleEdit={handleEdit}
      handleSelected={handleSelected}
      handleDeleteGroup={handleDeleteGroup}
      selectedIds={ids}
      permissionChange="commission.change"
      permissionCreate="commission.create"
      permissionDelete="commission.delete"
      permissionView="commission.view"
    />
  )
}

export default ListCommission
