import React, { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material"
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material"
import { TicketPricingType } from "../../../../gql/graphql"

interface TicketPricingDialogProps {
  open: boolean
  onClose: () => void
  onSave: (ticketPricings: TicketPricingType[]) => void
  ticketPricingsInit: TicketPricingType[]
}

const TicketPricingDialog: React.FC<TicketPricingDialogProps> = ({
  open,
  onClose,
  onSave,
  ticketPricingsInit,
}) => {
  const [ticketPricings, setTicketPricings] = useState<TicketPricingType[]>([])
  const [nameErrors, setNameErrors] = useState<{ [key: number]: string }>({})

  const handleAddTicketPricing = () => {
    setTicketPricings([
      ...ticketPricings,
      {
        name: "",
        price: 0,
        nbAvailible: null,
        descriptions: "",
        isPublic: false,
      },
    ])
  }

  const handleRemoveTicketPricing = (index: number) => {
    const newTicketPricings = [...ticketPricings]
    newTicketPricings.splice(index, 1)
    setTicketPricings(newTicketPricings)

    // Clear error for removed pricing
    const newNameErrors = { ...nameErrors }
    delete newNameErrors[index]
    setNameErrors(newNameErrors)
  }

  const validateUniqueName = (name: string, currentIndex: number): boolean => {
    return !ticketPricings.some(
      (pricing, index) =>
        index !== currentIndex &&
        pricing.name.toLowerCase() === name.toLowerCase(),
    )
  }

  const handleInputChange = (
    index: number,
    field: keyof TicketPricingType,
    value: any,
  ) => {
    const newTicketPricings = [...ticketPricings]

    if (field === "isPublic" && value === true) {
      // If setting a price to public, set all others to non-public
      newTicketPricings.forEach((pricing, i) => {
        pricing.isPublic = i === index
      })
    } else if (field === "name") {
      // Check for unique name
      if (!validateUniqueName(value, index)) {
        setNameErrors({ ...nameErrors, [index]: "Le nom doit être unique" })
      } else {
        const newNameErrors = { ...nameErrors }
        delete newNameErrors[index]
        setNameErrors(newNameErrors)
      }
    }

    newTicketPricings[index] = {
      ...newTicketPricings[index],
      [field]: value,
    }

    setTicketPricings(newTicketPricings)
  }

  const handleSave = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const allNamesUnique = ticketPricings.every((pricing, index) =>
      validateUniqueName(pricing.name, index),
    )
    if (allNamesUnique) {
      onSave(ticketPricings)
      onClose()
    } else {
      alert(
        "Veuillez vous assurer que tous les noms de tarification sont uniques avant d'enregistrer.",
      )
    }
  }

  useEffect(() => {
    setTicketPricings(ticketPricingsInit)
  }, [ticketPricingsInit])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          background: "dimgray",
        },
      }}
    >
      <form onSubmit={handleSave}>
        <DialogTitle variant="h2">
          Créer une tarification de billets
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {ticketPricings.map((pricing, index) => (
            <div
              key={index}
              style={{
                marginBottom: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                label="Nom"
                value={pricing.name}
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
                style={{ marginRight: "8px" }}
                error={!!nameErrors[index]}
                helperText={nameErrors[index] || ""}
                required
                variant="filled"
              />
              <TextField
                label="Prix"
                type="number"
                value={pricing.price}
                onChange={(e) =>
                  handleInputChange(index, "price", parseFloat(e.target.value))
                }
                style={{ marginRight: "8px" }}
                required
                variant="filled"
              />
              <TextField
                label="Nombre disponible"
                type="number"
                value={pricing.nbAvailible ?? ""}
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "nbAvailible",
                    e.target.value ? Math.abs(parseInt(e.target.value)) : null,
                  )
                }
                style={{ marginRight: "8px" }}
                variant="filled"
              />
              <TextField
                label="Descriptions"
                multiline
                value={pricing.descriptions ?? ""}
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "descriptions",
                    e.target.value ? e.target.value : "",
                  )
                }
                style={{ marginRight: "8px" }}
                variant="filled"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pricing.isPublic ?? false}
                    onChange={(e) =>
                      handleInputChange(index, "isPublic", e.target.checked)
                    }
                  />
                }
                label="Public"
              />
              <IconButton onClick={() => handleRemoveTicketPricing(index)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddTicketPricing}
          >
            Ajouter le prix des billets
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fermer</Button>
          <Button variant="contained" color="primary" type="submit">
            Sauvegarder
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default TicketPricingDialog
